// src/utils/pdfUtils.ts

import { jsPDF } from "jspdf"; // 이로써 모듈로 인식됩니다.
import { _fonts } from "./font";
import img from "../assets/images/img";

// src/utils/pdfUtils.ts에 추가
const loadImageAsBase64 = async (url: string) => {
  try {
    // 캐시 방지 쿼리 추가
    const cacheBusterUrl = `${url}?t=${new Date().getTime()}`; // 매번 새로운 쿼리 매개변수를 추가

    const response = await fetch(cacheBusterUrl, {
      mode: "cors",
      cache: "no-cache",
    }); // cache: "no-cache" 옵션 추가
    const blob = await response.blob();
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("이미지 로드 실패:", error);
    return ""; // 에러 발생 시 빈 문자열 반환
  }
};

export const generateMemberProfilePdf = async (lawyerData: MemberItem) => {
  const doc = new jsPDF("p", "mm", "a4");
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;
  let currentY = 20; // 현재 Y 위치를 추적하는 변수

  // 페이지 체크 헬퍼 함수
  const checkAndAddPage = (yPosition: number, increment: number = 0) => {
    if (yPosition + increment >= pageHeight - 30) {
      // 30mm 여백 확보
      doc.addPage();
      return 20; // 새 페이지의 시작 위치
    }
    return yPosition;
  };

  // const imageUrl = lawyerData.mainImg;
  //  ? lawyerData.mainImg : img.lawyer4;
  // eslint-disable-next-line
  const imageUrl = img.lawyer4; // 임시 이미지 URL
  const imageWidth = 60;
  const imageHeight = 90;
  const imageXPosition = pageWidth - imageWidth - 20; // 페이지의 오른쪽 여백을 20mm로 설정

  const base64Image = await loadImageAsBase64(lawyerData.mainImg);
  if (base64Image) {
    // 이미지를 오른쪽으로 배치
    doc.addImage(
      base64Image,
      "JPEG",
      imageXPosition,
      20,
      imageWidth,
      imageHeight
    );
  } else {
    console.warn("프로필 이미지를 추가하지 못했습니다.");
  }

  doc.addFileToVFS("malgun.ttf", _fonts);
  doc.addFont("malgun.ttf", "malgun", "normal");
  doc.setFont("malgun");

  doc.setFontSize(20);
  doc.setFont("malgun");

  // 변호사 이름과 직책
  currentY = 30;
  doc.text(lawyerData.nameKo, 20, currentY);
  doc.setTextColor(126, 126, 126);
  doc.setFontSize(12);
  doc.text(lawyerData.position, 45, currentY);
  doc.setTextColor(0, 0, 0);

  // 주요 경력
  currentY = checkAndAddPage(50);
  doc.setFontSize(12);
  doc.text("주요 경력", 22, currentY);
  doc.setDrawColor(0, 102, 204);
  doc.setLineWidth(1);
  doc.line(18, 46, 18, 51);
  doc.setFontSize(9);
  doc.text(lawyerData.firstMainCareer, 25, 58);
  doc.text(lawyerData.secondMainCareer, 25, 63);

  // 업무 분야
  currentY = checkAndAddPage(80);
  doc.setFontSize(12);
  doc.text("업무 분야", 22, currentY);
  doc.setDrawColor(0, 102, 204);
  doc.setLineWidth(1);
  doc.line(18, 76, 18, 81);

  doc.setFontSize(9);
  const workFieldStartingY = 88; // 시작 y 좌표
  const lineSpacing = 5; // 줄 간격
  const firstColumnX = 25; // 첫 번째 열의 X 좌표를 오른쪽으로 이동
  const columnWidth = 45;
  const secondColumnX = 30 + columnWidth; // 두 번째 열의 X 좌표

  if (
    Array.isArray(lawyerData.workFields) &&
    lawyerData.workFields.length > 0
  ) {
    lawyerData.workFields.forEach((field, index) => {
      // 첫 번째 열 또는 두 번째 열로 항목을 배치
      const isSecondColumn = index % 2 !== 0;
      const xPosition = isSecondColumn ? secondColumnX : firstColumnX;
      const yPosition =
        workFieldStartingY + Math.floor(index / 2) * lineSpacing;

      doc.text(field.workField, xPosition, yPosition);
    });
  } else {
    doc.text("업무 분야 정보가 없습니다.", firstColumnX, workFieldStartingY); // 데이터가 없을 때의 메시지
  }

  // 기존 코드에 있는 'secondMainCareer' 텍스트는 별도의 y 좌표로 추가 필요
  // doc.text(
  //   lawyerData.secondMainCareer,
  //   20,
  //   workFieldStartingY + lawyerData.workFields.length * lineSpacing + 5
  // );

  // 가로줄
  doc.setLineWidth(0.5);
  doc.setDrawColor(126, 126, 126);
  doc.line(20, 110, pageWidth - 20, 110);

  // 설명 텍스트 추가 (introduction)
  const introductionText = lawyerData.introduction.replace(/\s+/g, " ").trim();

  doc.setFontSize(9);
  const maxWidth = 170;
  const startingY = 120;

  // 텍스트를 여러 줄로 분할
  const lines = doc.splitTextToSize(introductionText, maxWidth);

  // 각 줄을 수동으로 그리기
  lines.forEach((line: string, index: number) => {
    const yPosition = startingY + index * lineSpacing;
    doc.text(line, 25, yPosition);
  });

  // 유동적인 가로줄 위치 계산 (줄 간격 반영)
  const lineYPosition = startingY + lines.length * lineSpacing;

  // 가로줄 그리기
  doc.setLineWidth(0.5);
  doc.setDrawColor(126, 126, 126);
  doc.line(20, lineYPosition, pageWidth - 20, lineYPosition);

  // 주요 처리 사례 - introduction 텍스트 끝나는 위치 기준으로 시작
  currentY = checkAndAddPage(lineYPosition + 15); // 가로줄 아래 여백 추가
  doc.setFontSize(12);
  doc.text("주요 처리 사례", 22, currentY);
  doc.setDrawColor(0, 102, 204);
  doc.setLineWidth(1);
  doc.line(18, currentY - 4, 18, currentY + 1);

  doc.setFontSize(9);
  lawyerData.handleCases.forEach((handleCase, index) => {
    currentY = checkAndAddPage(currentY + 6.5);
    doc.text(
      `${handleCase.startYear} - ${handleCase.endYear} ${handleCase.content}`,
      25,
      currentY
    );
  });

  // 학력
  currentY = checkAndAddPage(currentY + 15);
  doc.setFontSize(12);
  doc.text("학력", 22, currentY);
  doc.setDrawColor(0, 102, 204);
  doc.setLineWidth(1);
  doc.line(18, currentY - 4, 18, currentY + 1);

  doc.setFontSize(9);
  lawyerData.educations.forEach((education, index) => {
    currentY = checkAndAddPage(currentY + 6.5);
    doc.text(`${education.year} - ${education.content}`, 25, currentY);
  });

  // 경력
  currentY = checkAndAddPage(currentY + 15);
  doc.setFontSize(12);
  doc.text("경력", 22, currentY);
  doc.setDrawColor(0, 102, 204);
  doc.setLineWidth(1);
  doc.line(18, currentY - 4, 18, currentY + 1);

  doc.setFontSize(9);
  lawyerData.careers.forEach((career, index) => {
    currentY = checkAndAddPage(currentY + 6.5);
    doc.text(
      `${career.startYear} - ${career.endYear} ${career.content}`,
      25,
      currentY
    );
  });

  // 저서 활동 기타
  currentY = checkAndAddPage(currentY + 15);
  doc.setFontSize(12);
  doc.text("저서 활동 기타", 22, currentY);
  doc.setDrawColor(0, 102, 204);
  doc.setLineWidth(1);
  doc.line(18, currentY - 4, 18, currentY + 1);

  doc.setFontSize(9);
  lawyerData.licenses.forEach((license, index) => {
    currentY = checkAndAddPage(currentY + 6.5);
    doc.text(`${license.content}`, 25, currentY);
  });

  // 연락처 정보는 마지막 페이지 하단에 추가
  doc.setFontSize(10);
  doc.text(`전화: ${lawyerData.workNumber}`, 20, pageHeight - 20);
  doc.text(`이메일: ${lawyerData.email}`, 20, pageHeight - 15);

  // PDF 저장
  doc.save(`${lawyerData.nameKo}_profile.pdf`);
};

interface Career {
  startYear: string;
  endYear: string;
  content: string;
}

interface Education {
  year: string;
  content: string;
}

interface HandleCase {
  startYear: string;
  endYear: string;
  content: string;
}

interface License {
  content: string;
}

interface WorkField {
  workField: string;
}

interface MemberItem {
  id: number;
  nameKo: string;
  nameEn: string;
  nameCh: string;
  position: string;
  email: string;
  mainImg: string;
  firstMainCareer: string;
  secondMainCareer: string;
  workNumber: string;
  faxNumber: string;
  introduction: string;
  language: string;
  careers: Career[];
  educations: Education[];
  handleCases: HandleCase[];
  licenses: License[];
  workFields: WorkField[];
}
