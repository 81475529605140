import React from "react";
import logo from "../assets/images/FooterLogo.png";
import blog from "../assets/images/FooterBlog.png";
import { Link } from "react-router-dom";
// import insta from "../assets/images/FooterInsta.png";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="section1">
          <div className="footer-logo">
            <img src={logo} alt="Logo" className="footer-logo-image" />
          </div>
          <div className="footer-icons">
            <Link
              to="https://blog.naver.com/lawvax"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={blog} alt="blog" className="icon" />
            </Link>
            {/* <img src={insta} alt="insta" className="icon" /> */}
          </div>
        </div>
        <div className="section2">
          <p className="footer-links">
            법무법인 로백스 <span className="bar">|</span> 공동 대표 : 김기동,
            이동열
          </p>
          <p className="footer-address">
            서울 서초구 사임당로 18, 석오빌딩 3층, 9층
          </p>
          <p className="footer-contact">대표 전화 : 02)583 6300</p>
          <p className="footer-contact">
            팩스 : 3F 02)583 6303 | 9F 02)585 6404
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
