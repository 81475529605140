import popupImg from "../assets/images/popupImg.png";
import { useEffect, useState } from "react";

function Popup() {
  const [isPopupOpen, setIsPopupOpen] = useState(true);

  const beforeDate = localStorage.getItem("beforeDate");

  const nowDate = Math.floor(new Date().getDate()).toString();

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handleTodayClose = () => {
    if (beforeDate === nowDate) {
      localStorage.removeItem("beforeDate");
      localStorage.setItem("beforeDate", nowDate);
      setIsPopupOpen(false);
      return;
    }

    localStorage.setItem("beforeDate", nowDate);
    return setIsPopupOpen(false);
  };

  useEffect(() => {
    if (beforeDate !== null) {
      if (beforeDate === nowDate) {
        setIsPopupOpen(false);
      }
      if (beforeDate !== nowDate) {
        setIsPopupOpen(true);
      }
    }
  }, [beforeDate, nowDate]);

  return isPopupOpen ? (
    <div className="popup-wrap">
      <div className="popup-content">
        <img className="popup-img" src={popupImg} alt="popup" />
        <div className="popup-btn-wrap">
          <button
            className="popup-btn"
            onClick={handleTodayClose}
            style={{ borderRight: "1px solid #F2F2F1" }}
          >
            오늘 하루 닫기
          </button>
          <button className="popup-btn" onClick={handleClose}>
            닫기
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default Popup;
