import { useEffect, useState } from "react";
import home from "../../assets/images/icons/home.png";
import { getMembersAutoList } from "../../api/getMembersAutoList";
import { useMembersWorkFieldStore } from "../../stores/membersWorkFieldStore";
import {
  useMemberIsFirstPageStore,
  useMemberStore,
} from "../../stores/memberStore";
import img from "../../assets/images/img";
import { useMediaQuery } from "react-responsive";
import useUpdateUrlWithQuery from "../../utils/useUpdateUrlWithQuery";
import { useLocation } from "react-router-dom";

const TitleSection = () => {
  const searchValue = new URLSearchParams(useLocation().search).get("search");
  const [preSearchTerm, setPreSearchTerm] = useState<string>(searchValue || "");
  const [isFocused, setIsFocused] = useState(false);
  const isDesktop = useMediaQuery({ query: "(min-width: 1403px)" });
  const setSearchTerm = useMemberStore((state) => state.setSearchTerm);
  const selectedWorkField = useMembersWorkFieldStore(
    (state) => state.selectedWorkField
  );
  const setSelectedWorkField = useMembersWorkFieldStore(
    (state) => state.setSelectedWorkField
  );
  const setIsFirstPage = useMemberIsFirstPageStore(
    (state) => state.setIsFirstPage
  );

  const [searchAutoComplete, setSearchAutoComplete] = useState<string[][]>([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[][]>(
    []
  );

  const updateUrlWithQuery = useUpdateUrlWithQuery();

  useEffect(() => {
    const fetchAutoComplete = async () => {
      try {
        const data = await getMembersAutoList();
        setSearchAutoComplete(data);
      } catch (error) {
        console.error("구성원 페이지 자동완성 검색 중 에러 발생:", error);
      }
    };

    fetchAutoComplete();
  }, []);

  useEffect(() => {
    if (preSearchTerm) {
      const filtered = searchAutoComplete.filter(([name, position]) => {
        return name.includes(preSearchTerm);
      });
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  }, [preSearchTerm, searchAutoComplete]);

  const handleSearch = () => {
    if (preSearchTerm.trim() === "") {
      setSearchTerm("");
      updateUrlWithQuery([], true);
    } else {
      setSearchTerm(preSearchTerm);
      setFilteredSuggestions([]);
      updateUrlWithQuery([{ paramsKey: "search", paramsValue: preSearchTerm }]);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
      setIsFirstPage(true);
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setPreSearchTerm(suggestion);
    setSearchTerm(suggestion);
    setFilteredSuggestions([]);
  };

  const toggleWorkField = (workField: string) => {
    setIsFirstPage(true);
    setSelectedWorkField(selectedWorkField === workField ? "" : workField);
  };

  const handleInputFocus = () => setIsFocused(true);
  const handleInputBlur = () => setTimeout(() => setIsFocused(false), 100);

  return (
    <section className="title-section">
      {isDesktop && (
        <div className="process">
          <img src={home} alt="" />
          <span>HOME</span>
          <span>
            <img src={img.icons.grayRight} alt="" />
          </span>
          <span>구성원</span>
          <span className="search">{">"}</span>
          <span className="search">인물검색</span>
        </div>
      )}
      <div className="sub-title">
        <p className="roman-title">
          <span className="blue">P</span>
          ROFESSIONAL
        </p>
      </div>
      <div className="search-section">
        <div className="search-bar-wrap">
          <div className="search-bar">
            <input
              type="text"
              placeholder="이름을 검색해주세요."
              className="search-input"
              value={preSearchTerm}
              onChange={(e) => setPreSearchTerm(e.target.value)}
              onKeyPress={handleKeyPress}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
            <div>
              <img
                className="search-icon"
                src={img.icons.search}
                alt="search-icon"
                onClick={handleSearch}
              />
            </div>
          </div>

          {isFocused && filteredSuggestions.length > 0 && (
            <ul className="autocomplete-suggestions">
              {filteredSuggestions.map(([name, position], index) => (
                <li key={index} onMouseDown={() => handleSuggestionClick(name)}>
                  {name} ({position})
                </li>
              ))}
            </ul>
          )}

          <div className="search-category-wrap">
            <button
              className={
                selectedWorkField === "기업감사 / 내부통제" ? "active" : ""
              }
              onClick={() => toggleWorkField("기업감사 / 내부통제")}
            >
              <span>기업감사 / 내부통제</span>
            </button>
            <button
              className={selectedWorkField === "기술보호" ? "active" : ""}
              onClick={() => toggleWorkField("기술보호")}
            >
              <span>기술보호</span>
            </button>
            <button
              className={
                selectedWorkField === "금융 / 가상자산" ? "active" : ""
              }
              onClick={() => toggleWorkField("금융 / 가상자산")}
            >
              <span>금융 / 가상자산</span>
            </button>
            <button
              className={selectedWorkField === "건설 / 부동산" ? "active" : ""}
              onClick={() => toggleWorkField("건설 / 부동산")}
            >
              <span>건설 / 부동산</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TitleSection;
