import { ReactComponent as People } from "../assets/images/ic_admin_people.svg";
import { ReactComponent as List } from "../assets/images/ic_admin_list.svg";
import { ReactComponent as Box } from "../assets/images/ic_admin_box.svg";
import { ReactComponent as Media } from "../assets/images/ic_admin_media.svg";

export const MANAGEMENT_TAB_CONTENTS = [
  {
    Icon: People,
    label: "구성원 관리",
    link: "/admin/people-management",
    extraLink: "/admin/people-register",
  },
  {
    Icon: List,
    label: "업무분야 관리",
    link: "/admin/work-category-manegement",
  },
  {
    Icon: Box,
    label: "소식, 인재영입 관리",
    link: "/admin/post-manegement",
    extraLink: "/admin/post-register",
  },
  {
    Icon: Media,
    label: "언론보도 관리",
    link: "/admin/media-manegement",
    extraLink: "/admin/media-register",
  },
];

export const POST_TAB_CONTENT = {
  전체보기: 0,
  Lawvax소식: 0,
  인재영입: 0,
  비공개: 0,
};

export const MEDIA_TAB_CONTENT = {
  언론보도: 0,
  비공개: 0,
};

export const WORK_CATEGORY_CONTENT = [
  "형사",
  "민사 · 행정 · 가사",
  "금융 · 가상자산",
  "건설 · 부동산",
  "IT · 첨단 · 기술보호 · 개인정보",
  "기업 자문 · 감사 대행",
  "조세 · 관세 · 공정거래",
  "기업 M&A · 구조조정 · 회생",
  "조선 · 해운",
  "방산 · 우주항공",
];

export const WORK_POSITION_LIST = [
  "대표 변호사",
  "파트너 변호사",
  "변호사",
  "고문",
];

export const MAIN_TASKS_LIST = [
  "형사",
  "민사 · 행정 · 가사",
  "금융 · 가상자산",
  "기업 자문 · 감사 대행",
  "IT · 첨단 · 기술보호 · 개인정보",
  "조세 · 관세 · 공정거래",
  "기업 M&A · 구조조정 · 회생",
  "건설 · 부동산",
  "조선 · 해운",
  "방산 · 우주항공",
];

export const ADD_TASKS_LIST = ["기업 감사/내부통제 지원센터", "기술 보호 센터"];

export const POST_REGISTER_CATEGORY = ["Lawvax소식", "인재영입"];
