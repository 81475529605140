import { useNavigate, useLocation } from "react-router-dom";

interface QueryParam {
  paramsKey: string;
  paramsValue: string;
}

function useUpdateUrlWithQuery() {
  const navigate = useNavigate();
  const location = useLocation();

  return (params: QueryParam[], empty?: boolean) => {
    if (empty) {
      return navigate(`${location.pathname}`);
    }

    const searchParams = new URLSearchParams(location.search);

    params.forEach((param) => {
      searchParams.set(param.paramsKey, param.paramsValue);
    });

    return navigate(`${location.pathname}?${searchParams.toString()}`);
  };
}

export default useUpdateUrlWithQuery;
