import home from "../../assets/images/icons/home.png";
import list from "../../assets/images/icons/list.png";
import { Link, useLocation, useParams } from "react-router-dom";
import { useNewsStore } from "../../stores/newsStore";
import { useEffect, useState, useRef } from "react";
import { getNewsDetailData } from "../../api/getNewsDetailData";
import { getNewsDetailListData } from "../../api/getNewsDetailListData";
import img from "../../assets/images/img";
import SwiperCore from "swiper";
import { useMediaQuery } from "react-responsive";

const NewsPostPage = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1403px)" });
  const { id } = useParams();
  const category = new URLSearchParams(useLocation().search).get("category");
  const selectedTab = useNewsStore((state) => state.selectedTab);
  const setSelectedTab = useNewsStore((state) => state.setSelectedTab);
  const [newsDetail, setNewsDetail] = useState({
    title: "",
    content: "",
    mainImg: "",
    createdAt: "",
  });
  const [relatedNews, setRelatedNews] = useState([
    {
      id: 0,
      title: "",
      createdAt: "",
      mainImg: "",
    },
  ]);

  if (category) {
    setSelectedTab(category);
  }

  // Swiper 인스턴스 참조 생성
  const swiperRef = useRef<SwiperCore | null>(null);

  useEffect(() => {
    const fetchNewsDetail = async () => {
      if (id) {
        const data = await getNewsDetailData(Number(id));
        setNewsDetail(data);
      }
    };

    const fetchRelatedNews = async () => {
      if (selectedTab) {
        const data = await getNewsDetailListData(
          Number(id),
          String(selectedTab)
        );
        setRelatedNews(data);
      }
    };

    fetchNewsDetail();
    fetchRelatedNews();
  }, [id, selectedTab]);

  useEffect(() => {
    if (id && swiperRef.current) {
      const currentNewsIndex = relatedNews.findIndex(
        (news) => news.id === Number(id)
      );
      if (currentNewsIndex !== -1) {
        swiperRef.current.slideTo(currentNewsIndex, 500);
      }
    }
  }, [id, relatedNews]);

  // const handlePrev = () => {
  //   if (swiperRef.current) {
  //     swiperRef.current.slidePrev();
  //   }
  // };

  // const handleNext = () => {
  //   if (swiperRef.current) {
  //     swiperRef.current.slideNext();
  //   }
  // };

  return (
    <div className="news-post-page">
      {isDesktop && (
        <div className="process">
          <img src={home} alt="home" />
          <span>HOME</span>
          <span>
            <img src={img.icons.grayRight} alt="" />
          </span>
          <span>법인소식</span>
          <span>
            <img src={img.icons.grayRight} alt="" />
          </span>
          <span>{selectedTab}</span>
          <span>
            <img src={img.icons.grayRight} alt="" />
          </span>
          <span>게시글</span>
        </div>
      )}
      <div className="title">
        <h1>{newsDetail.title}</h1>
      </div>
      <div className="section1">
        <span className="date">{newsDetail.createdAt.slice(0, 10)}</span>
      </div>
      <div className="content">
        <img
          src={
            newsDetail.mainImg === null || newsDetail.mainImg === "null"
              ? img.defaultNews
              : newsDetail.mainImg
          }
          alt=""
        />
        <p dangerouslySetInnerHTML={{ __html: newsDetail.content }}></p>
      </div>
      {relatedNews?.length > 0 && (
        <div className="related-news-wrap">
          <div className="related-news">
            <div className="list">
              <Link to="/news">
                <img src={list} alt="목록보기" />
                <span>목록보기</span>
              </Link>
            </div>
            <h3>다음 소식</h3>
          </div>
          <div className="related-news-list">
            {relatedNews.length > 0 &&
              relatedNews?.slice(0, 3).map((news, index) => (
                <Link key={index} to={`/news/post/${news.id}`} className="list">
                  <h1>{news.title}</h1>
                  <span>{news.createdAt.slice(0, 10)}</span>
                </Link>
              ))}
          </div>
          {/* <div className="swiper-container">
            <button className="nav-button prev" onClick={handlePrev}>
              <img src={img.icons.ArrowLeft} alt="Previous Slide" />
            </button>
            <Swiper
              modules={[Navigation, Pagination]}
              slidesPerView={3}
              spaceBetween={22}
              // centeredSlides={true}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
              }}
              onSwiper={(swiper: any) => (swiperRef.current = swiper)}
            >
              {relatedNews?.map((news, index) => (
                <SwiperSlide key={index}>
                  <div className="content">
                    <div className="img-wrap">
                      <Link to={`/news/post/${news.id}`}>
                        <img
                          src={
                            news.mainImg === null || news.mainImg === "null"
                              ? img.defaultNews
                              : news.mainImg
                          }
                          alt={news.title}
                        />
                      </Link>
                    </div>
                    <div className="text-wrap">
                      <Link to={`/news/post/${news.id}`}>
                        <h3 className="title">{news.title}</h3>
                      </Link>
                      <div className="bottom">
                        <span className="date">
                          {news.createdAt.slice(0, 10)}
                        </span>
                        <Link to={`/news/post/${news.id}`}>
                          <span className="more">자세히보기</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <button className="nav-button next" onClick={handleNext}>
              <img src={img.icons.ArrowRight} alt="Next Slide" />
            </button>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default NewsPostPage;
