import { useEffect, useState } from "react";
import search from "../../assets/images/icons/search.png";
import { useNavigate } from "react-router-dom";
import { getLandingSearch } from "../../api/getLandingSearch";
import useSearchStore from "../../stores/searchStore";
import backgroundVideo from "../../assets/mainVideo.mp4";

const MainSection = () => {
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState(false);
  const { setSearchResults, setKeyword, keyword } = useSearchStore();

  useEffect(() => {
    setKeyword("");
    // eslint-disable-next-line
  }, []);

  const handleSearch = async () => {
    // console.log("검색된 키워드:", keyword);
    try {
      const searchResults = await getLandingSearch(keyword);
      setSearchResults(searchResults);
      navigate(`/landing-search?keyword=${keyword}`);
    } catch (error) {
      console.error("검색 실패:", error);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="main-section">
      <video autoPlay loop muted playsInline className="background-video">
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className="main-content">
        <h1 className="main-title">
          <span className="highlight">Difference. Experience.</span>
        </h1>
        <p className="main-subtitle">
          법률 리스크 예방 및 대응을 위한
          <br /> 기업 금융 IT 분야 로펌
        </p>
        <div className="search-bar">
          <input
            type="text"
            placeholder="키워드를 입력해주세요."
            className="search-input"
            onFocus={() => setIsFocused(true)}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <img
            className="search-icon"
            src={search}
            alt="search-icon"
            onClick={handleSearch}
          />
        </div>
      </div>
    </div>
  );
};

export default MainSection;
