import TitleSection from "./TitleSection";
// import HiredSection from "./HiredSection";
import Lawyersection from "./LawyerSection";
import { useMembersWorkFieldStore } from "../../stores/membersWorkFieldStore";

const MembersPage = () => {
  const setSelectedWorkField = useMembersWorkFieldStore(
    (state) => state.setSelectedWorkField
  );

  setSelectedWorkField("");

  return (
    <div className="members-page">
      <TitleSection />
      <Lawyersection />
    </div>
  );
};

export default MembersPage;
