import up from "../../assets/images/icons/up.png";
import down from "../../assets/images/icons/down.png";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// eslint-disable-next-line
import { useMediaQuery } from "react-responsive";
import { getMembersList } from "../../api/getMembersList";
import { useMembersWorkFieldStore } from "../../stores/membersWorkFieldStore";
import {
  useMemberIsFirstPageStore,
  useMemberStore,
} from "../../stores/memberStore";
import img from "../../assets/images/img";
// import { isDifferentCheckMembersPage } from "../../utils/isDifferentCheck";

interface Lawyer {
  id: number;
  mainImg: string;
  nameKo: string;
  nameCh: string;
  position: string;
  email: string;
  firstMainCareer: string;
  secondMainCareer: string;
}

const Lawyersection = () => {
  const searchTerm = useMemberStore((state) => state.searchTerm);
  const setSearchTerm = useMemberStore((state) => state.setSearchTerm);
  const searchValue = new URLSearchParams(useLocation().search).get("search");
  const selectedWorkField = useMembersWorkFieldStore(
    (state) => state.selectedWorkField
  );
  const [selectedLawyerIndex, setSelectedLawyerIndex] = useState<number | null>(
    0
  );
  const [showAll, setShowAll] = useState(false);
  const isFirstPage = useMemberIsFirstPageStore((state) => state.isFirstPage);
  const setIsFirstPage = useMemberIsFirstPageStore(
    (state) => state.setIsFirstPage
  );

  const [lawyerList, setLawyerList] = useState<Lawyer[]>([]);

  useEffect(() => {
    const fetchLawyerList = async () => {
      try {
        const data = await getMembersList(
          isFirstPage,
          searchValue || searchTerm,
          selectedWorkField
        );
        setLawyerList(data.lawyerList);
        setShowAll(data.hasNextPage);
      } catch (error) {
        console.error("변호사 조회 중 에러 발생:", error);
      }
    };

    fetchLawyerList();
    // eslint-disable-next-line
  }, [selectedWorkField, searchTerm, searchValue, isFirstPage]);

  const handleClick = (index: number) => {
    setSelectedLawyerIndex(index);
  };

  const handleToggle = () => {
    setIsFirstPage(!isFirstPage);
    setShowAll((prevState) => !prevState);
  };

  const handleMouseEnter = (index: number) => {
    setSelectedLawyerIndex(index);
  };

  const handleMouseLeave = () => {
    setSelectedLawyerIndex(null);
  };

  useEffect(() => {
    return () => {
      setSearchTerm("");
    };
  }, []);

  return (
    <section className="lawyer-section">
      {lawyerList?.length > 0 ? (
        <div className="grid-wrap">
          {lawyerList.map((lawyer, index) => (
            <div
              key={index}
              className={`lawyer-item ${selectedLawyerIndex === index || (index === 0 && selectedLawyerIndex === null) ? "selected" : ""}`}
              onClick={() => handleClick(index)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <Link to={`/members/profile/${lawyer.id}`}>
                <img
                  src={lawyer.mainImg ? lawyer.mainImg : img.defaultLawyer}
                  alt=""
                />
              </Link>
              <div className="text-wrap">
                <p className="p1">
                  <span>{lawyer.nameKo}</span>
                  {/* <span> {lawyer.nameCh}</span> 추 후 한자 이름 입력 자리 */}
                  <span className="p2">{lawyer.position} </span>
                </p>
                <p className="p2" style={{ margin: "5px 0px" }}>
                  {lawyer.email}
                </p>
                <p className="class-wrap">
                  {lawyer.firstMainCareer.length > 1 && (
                    <p className="p3">{lawyer.firstMainCareer}</p>
                  )}
                  {lawyer.secondMainCareer.length > 1 && (
                    <p className="p3">{lawyer.secondMainCareer}</p>
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="grid-wrap">
          <div>검색결과가 없습니다.</div>
        </div>
      )}
      {showAll && isFirstPage && (
        <button className="toggle-button" onClick={handleToggle}>
          더보기
          <img src={down} alt="" />
        </button>
      )}
      {!showAll && !isFirstPage && (
        <button className="toggle-button" onClick={handleToggle}>
          접기
          <img src={up} alt="" />
        </button>
      )}
    </section>
  );
};

export default Lawyersection;
