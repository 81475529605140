import { useEffect, useRef, useState } from "react";
import home from "../../assets/images/icons/home.png";
import save from "../../assets/images/icons/saveBlue.png";
import up from "../../assets/images/icons/up.png";
import down from "../../assets/images/icons/down.png";
import more from "../../assets/images/more.png";

import { getMembersDetail } from "../../api/getMembersDetail";
import { generateMemberProfilePdf } from "../../utils/pdf";
import { Link, useParams } from "react-router-dom";
import img from "../../assets/images/img";
import { useMediaQuery } from "react-responsive";
import { getLawyerMediaList } from "../../api/getWorkFieldNewsLetter";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { formatDateToDotSeparated } from "../../utils/dateFormat";
import { swiperPerViewCount } from "../../utils/swiperPerViewCount";

const MemberProfilePage = () => {
  const { id } = useParams();
  const newsSectionRef = useRef(null);
  const [lawyerData, setLawyerData] = useState<MemberItem>({
    id: 0,
    nameKo: "",
    nameEn: "",
    nameCh: "",
    position: "",
    email: "",
    mainImg: "",
    firstMainCareer: "",
    secondMainCareer: "",
    workNumber: "",
    faxNumber: "",
    introduction: "",
    language: "",
    careers: [],
    educations: [],
    handleCases: [],
    licenses: [],
    workFields: [],
  });
  const [isEducationExpanded, setEducationExpanded] = useState(false);
  const [isExperienceExpanded, setExperienceExpanded] = useState(false);
  const [isEtcWorkFieldsExpanded, setEtcWorkFieldsExpanded] = useState(false);
  const [isHandleCasesExpanded, setHandleCasesExpanded] = useState(false);
  const [isLicensesExpanded, setLicensesExpanded] = useState(false);
  const isDesktop = useMediaQuery({ query: "(min-width: 1403px)" });
  const [newsLetterList, setNewsLetterList] = useState<MediaItem[]>([]);
  const swiperRef = useRef<any>(null);
  const [isMediaLoading, setIsMediaLoading] = useState(true);
  const formatWorkNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/(\d{2,3})(\d{3,4})(\d{4})/, "$1-$2-$3");
  };

  const toggleExpand = (section: any) => {
    switch (section) {
      case "education":
        setEducationExpanded(!isEducationExpanded);
        break;
      case "experience":
        setExperienceExpanded(!isExperienceExpanded);
        break;
      case "etcWorkFields":
        setEtcWorkFieldsExpanded(!isEtcWorkFieldsExpanded);
        break;
      case "handleCases":
        setHandleCasesExpanded(!isHandleCasesExpanded);
        break;
      case "licenses":
        setLicensesExpanded(!isLicensesExpanded);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const menuItems = document.querySelectorAll<HTMLSpanElement>(".menu-item");

    const removeActiveClass = () => {
      menuItems.forEach((item) => {
        item.classList.remove("active");
      });
    };

    menuItems.forEach((item) => {
      item.addEventListener("click", () => {
        const targetId = item.getAttribute("data-target");
        if (targetId) {
          const targetElement = document.getElementById(targetId);
          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            removeActiveClass();
            item.classList.add("active");
          }
        }
      });
    });

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const targetId = entry.target.getAttribute("id");
            if (targetId) {
              const correspondingMenuItem =
                document.querySelector<HTMLSpanElement>(
                  `.menu-item[data-target="${targetId}"]`
                );
              if (correspondingMenuItem) {
                removeActiveClass();
                correspondingMenuItem.classList.add("active");
              }
            }
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    const sections = document.querySelectorAll<HTMLElement>(".section");
    sections.forEach((section) => observer.observe(section));

    return () => {
      menuItems.forEach((item) => item.removeEventListener("click", () => {}));
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const fetchLawyer = async () => {
      try {
        const data = await getMembersDetail(Number(id));
        setLawyerData(data);
      } catch (error) {
        console.error("변호사 데이터 조회 중 에러 발생:", error);
      }
    };

    const fetchNewsLetter = async () => {
      try {
        const data = await getLawyerMediaList(Number(id));
        setNewsLetterList(data);
        setIsMediaLoading(false);
      } catch (error) {
        console.error("뉴스레터 조회 중 에러 발생:", error);
      }
    };
    fetchLawyer();
    fetchNewsLetter();
  }, [id]);

  const handlePdfDownload = () => {
    if (lawyerData) {
      generateMemberProfilePdf(lawyerData); // lawyerData를 인자로 전달하여 PDF 생성
    } else {
      console.error("변호사 데이터를 불러오지 못했습니다.");
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <div className="member-profile-page">
      <div className="title-section">
        {isDesktop && (
          <div className="process">
            <img src={home} alt="" />
            <span>HOME</span>
            <span>
              <img src={img.icons.grayRight} alt="" />
            </span>
            <span>구성원</span>
            <span className="search">
              <img src={img.icons.grayRight} alt="" />
            </span>
            <span className="search">프로필</span>
          </div>
        )}
      </div>
      <div className="save-wrap">
        <div className="save" onClick={handlePdfDownload}>
          <img src={save} alt="" />
          <span>상세정보 저장하기</span>
        </div>
      </div>
      <div className="profile-main">
        <div className="content-wrap">
          <div className="text-wrap">
            <p className="name">
              {lawyerData?.nameKo}
              <span className="job">{lawyerData?.position}</span>
            </p>
            <p className="sub-title01">
              <span>주요경력</span>
            </p>
            <div className="sub-content01">
              {lawyerData?.firstMainCareer.length > 1 && (
                <p>{lawyerData?.firstMainCareer}</p>
              )}
              {lawyerData?.secondMainCareer.length > 1 && (
                <p>{lawyerData?.secondMainCareer}</p>
              )}
            </div>
            <p className="sub-title02">
              <span>업무분야</span>
            </p>
            <div className="sub-content02">
              {lawyerData?.workFields.map((item, index) => (
                <span key={index}>{item.workField}</span>
              ))}
            </div>
            <p className="ment">{lawyerData?.introduction}</p>
          </div>
          <div className="img-wrap">
            {/* <img src={lawyerImage} alt="" /> */}
            <img src={lawyerData?.mainImg} alt="" />
          </div>
        </div>
      </div>
      <div className="sub-menu">
        <span className="menu-item" data-target="case-section">
          주요 처리사례
        </span>
        <span className="menu-item" data-target="education-section">
          학력
        </span>
        <span className="menu-item" data-target="experience-section">
          경력
        </span>
        <span className="menu-item" data-target="media-section">
          언론보도
        </span>
      </div>
      <div className="fold-section">
        {lawyerData?.handleCases.length > 0 && (
          <div className="case section" id="case-section">
            <div className="title">
              <p>주요 처리사례</p>
            </div>
            <div className="content-wrap">
              {lawyerData?.handleCases
                .slice(
                  0,
                  isHandleCasesExpanded ? lawyerData?.handleCases.length : 4
                )
                .map((item, index) => (
                  <div key={index} className="content">
                    <div className="year-wrap">
                      <span>{item.startYear}</span>
                      {item.endYear.length > 1 && <span>-</span>}
                      <span>{item.endYear}</span>
                    </div>
                    <p>{item.content}</p>
                  </div>
                ))}
              {lawyerData?.handleCases.length > 4 && (
                <button onClick={() => toggleExpand("handleCases")}>
                  {isHandleCasesExpanded ? "접기" : "더보기"}
                  {isHandleCasesExpanded ? (
                    <img src={up} alt="" />
                  ) : (
                    <img src={down} alt="" />
                  )}
                </button>
              )}
            </div>
          </div>
        )}
        {lawyerData?.educations.length > 0 && (
          <div className="education section" id="education-section">
            <div className="title">
              <p>학력</p>
            </div>
            <div className="content-wrap">
              {lawyerData?.educations
                .slice(
                  0,
                  isEducationExpanded ? lawyerData?.educations.length : 4
                )
                .map((item, index) => (
                  <div key={index} className="content">
                    <div className="year-wrap">
                      <span>{item.year}</span>
                    </div>
                    <p>{item.content}</p>
                  </div>
                ))}
              {lawyerData?.educations.length > 4 && (
                <button onClick={() => toggleExpand("education")}>
                  {isEducationExpanded ? "접기" : "더보기"}
                  {isEducationExpanded ? (
                    <img src={up} alt="" />
                  ) : (
                    <img src={down} alt="" />
                  )}
                </button>
              )}
            </div>
          </div>
        )}
        {lawyerData?.careers.length > 0 && (
          <div className="experience section" id="experience-section">
            <div className="title">
              <p>경력</p>
            </div>
            <div className="content-wrap">
              {lawyerData?.careers
                .slice(0, isExperienceExpanded ? lawyerData?.careers.length : 4)
                .map((item, index) => (
                  <div key={index} className="content">
                    <div className="year-wrap">
                      <span>{item.startYear}</span>
                      {item.endYear.length > 1 && <span>-</span>}
                      <span>{item.endYear}</span>
                    </div>
                    <p>{item.content}</p>
                  </div>
                ))}
              {lawyerData?.careers.length > 4 && (
                <button onClick={() => toggleExpand("experience")}>
                  {isExperienceExpanded ? "접기" : "더보기"}
                  {isExperienceExpanded ? (
                    <img src={up} alt="" />
                  ) : (
                    <img src={down} alt="" />
                  )}
                </button>
              )}
            </div>
          </div>
        )}
        {lawyerData?.licenses.length > 0 && (
          <div className="other-section section" id="other-section">
            <div className="title">
              <p>저서 및 기타활동</p>
            </div>
            <div className="content-wrap">
              {lawyerData?.licenses
                .slice(0, isLicensesExpanded ? lawyerData?.licenses.length : 4)
                .map((item, index) => (
                  <div key={index} className="content">
                    <div className="year-wrap">
                      <span>{item.startYear}</span>
                      {item.endYear && item.endYear.length > 1 && (
                        <span>-</span>
                      )}
                      <span>{item.endYear}</span>
                    </div>
                    <p>{item.content}</p>
                  </div>
                ))}
              {lawyerData?.licenses.length > 4 && (
                <button onClick={() => toggleExpand("licenses")}>
                  {isLicensesExpanded ? "접기" : "더보기"}
                  {isLicensesExpanded ? (
                    <img src={up} alt="" />
                  ) : (
                    <img src={down} alt="" />
                  )}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      {!isMediaLoading && newsLetterList.length > 0 && (
        <div className="recent-news" ref={newsSectionRef} id="media-section">
          <h1>언론보도</h1>
          <Link to="/news">
            <button className="more">
              <img src={more} alt="" />
            </button>
          </Link>

          <div className="swiper-container">
            <button className="nav-button prev" onClick={handlePrev}>
              <img src={img.icons.ArrowLeft} alt="Previous Slide" />
            </button>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={1}
              spaceBetween={10}
              scrollbar={{ draggable: true }}
              slidesOffsetBefore={0}
              slidesOffsetAfter={0}
              onSwiper={(swiper: any) => (swiperRef.current = swiper)}
              breakpoints={{
                300: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: swiperPerViewCount(newsLetterList.length, 4),
                },
              }}
            >
              {newsLetterList.map((item, index) => (
                <SwiperSlide key={index}>
                  <Link
                    to={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="newsletter-content">
                      <div className="mark">
                        <span>{item.work_field}</span>
                      </div>
                      <div className="title">
                        <span>{item.title}</span>
                      </div>
                      <div className="bottom">
                        <span className="date">{item.media_company}</span>
                        <span className="date">
                          {formatDateToDotSeparated(item.mediaDate)}
                        </span>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
            <button className="nav-button next" onClick={handleNext}>
              <img src={img.icons.ArrowRight} alt="Next Slide" />
            </button>
          </div>
        </div>
      )}
      <div className="info" style={{ marginTop: 30 }}>
        <p className="phone">
          <span>T</span>
          {formatWorkNumber(lawyerData?.workNumber)}
        </p>
        <p className="email">
          <span>E</span>
          {lawyerData?.email}
        </p>
      </div>
    </div>
  );
};

export default MemberProfilePage;

interface Career {
  startYear: string;
  endYear: string;
  content: string;
}

interface Education {
  year: string;
  content: string;
}

interface HandleCase {
  startYear: string;
  endYear: string;
  content: string;
}

interface License {
  content: string;
  startYear: string | null;
  endYear: string | null;
}

interface WorkField {
  workField: string;
}

interface MemberItem {
  id: number;
  nameKo: string;
  nameEn: string;
  nameCh: string;
  position: string;
  email: string;
  mainImg: string;
  firstMainCareer: string;
  secondMainCareer: string;
  workNumber: string;
  faxNumber: string;
  introduction: string;
  language: string;
  careers: Career[];
  educations: Education[];
  handleCases: HandleCase[];
  licenses: License[];
  workFields: WorkField[];
}

interface MediaItem {
  id: number;
  title: string;
  work_field: string;
  media_company: string;
  mediaDate: string;
  link: string;
}
