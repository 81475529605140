/* eslint-disable react-hooks/exhaustive-deps */
import img from "../../assets/images/img";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Link, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { getWorkFieldDetail } from "../../api/getWorkFieldDetail";
import { getWorkFieldMembers } from "../../api/getWorkFieldMembers";
import { getWorkFieldNewsLetter } from "../../api/getWorkFieldNewsLetter";
import { useMediaQuery } from "react-responsive";
import { formatDateToDotSeparated } from "../../utils/dateFormat";
import { swiperPerViewCount } from "../../utils/swiperPerViewCount";

interface MediaItem {
  id: number;
  title: string;
  work_field: string;
  link: string;
  media_company: string;
  mediaDate: string;
}

const WorkFieldDetailPage = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1403px)" });
  const { workField } = useParams();
  const [workFieldData, setWorkFieldData] = useState({
    name: "",
    mainImg: "",
    introduction: "",
    mainCases: [
      {
        start_year: "",
        end_year: "",
        content: "",
      },
    ],
    subCases: [
      {
        content: "",
      },
    ],
  });
  const [lawyerList, setLawyerList] = useState([
    {
      id: 0,
      nameKo: "",
      nameCh: "",
      mainImg: "",
      position: "",
      email: "",
      firstMainCareer: "",
      secondMainCareer: "",
    },
  ]);
  const [mediaList, setMediaList] = useState<MediaItem[]>([]);
  const swiperRef = useRef<any>(null);

  const introduce = useRef(null);
  const workSectionRef = useRef(null);
  const membersSectionRef = useRef(null);
  const newsSectionRef = useRef(null);
  const [showAll, setShowAll] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isMediaLoading, setIsMediaLoading] = useState(true);

  // 업무분야 조회
  const fetchWorkField = async () => {
    try {
      const data = await getWorkFieldDetail(String(workField));
      setWorkFieldData(data);
    } catch (error) {
      console.error("업무분야 조회 중 에러 발생:", error);
    }
  };

  // 구성원 조회
  const fetchMembers = async () => {
    try {
      const data = await getWorkFieldMembers(String(workField), isFirstPage);
      setLawyerList(data.lawyerList);
      setShowAll(data.hasNextPage);
    } catch (error) {
      console.error("구성원 조회 중 에러 발생:", error);
    }
  };

  // 뉴스레터 조회
  const fetchNewsLetter = async () => {
    try {
      const data = await getWorkFieldNewsLetter(String(workField));
      setMediaList(data);
      setIsMediaLoading(false);
    } catch (error) {
      console.error("뉴스레터 조회 중 에러 발생:", error);
    }
  };

  useEffect(() => {
    fetchWorkField();
    fetchNewsLetter();
    fetchMembers();
  }, [workField]);

  useEffect(() => {
    setIsFirstPage(true);
  }, [workField]);

  useEffect(() => {
    fetchMembers();
  }, [isFirstPage]);

  const handleToggle = () => {
    setShowAll((prevState) => !prevState);
    setIsFirstPage((prevState) => !prevState);
  };

  const scrollToSection = (sectionRef: any) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <div className="services-detail-page">
      {isDesktop && (
        <div className="process">
          <img src={img.icons.home} alt="home" />
          <span>HOME</span>
          <span>
            <img src={img.icons.grayRight} alt="" />
          </span>
          <span>업무분야</span>
          <span>
            <img src={img.icons.grayRight} alt="" />
          </span>
          <span>{workField}</span>
        </div>
      )}
      <div className="title">
        <p>
          <span>F</span>
          IELD
          <br />
          OF WORK
        </p>
      </div>
      <div className="sub-title" ref={introduce}>
        <p>{workField}</p>
        <img src={workFieldData.mainImg} alt="" />
      </div>
      <div className="content-wrap">
        <div className="content-left">
          <div className="sub-title-ment">
            <p>{workFieldData.introduction}</p>
          </div>
          <div className="main-field-of-work" ref={workSectionRef}>
            <h1>대표 사례</h1>
            <ul>
              {workFieldData.mainCases.map((mainCase, index) => (
                <li key={index}>
                  <span className="year">🔹</span>
                  <span className="content">{mainCase.content}</span>
                </li>
              ))}
            </ul>
            {workFieldData.subCases.length > 0 && (
              <>
                <h1>추가 사례</h1>
                <ul>
                  {workFieldData.subCases.map((subCase, index) => (
                    <li key={index}>
                      <span className="year">🔹</span>
                      <span className="content">{subCase.content}</span>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <div className="members" ref={membersSectionRef}>
            <h1>구성원</h1>
            <div className="members-grid">
              {lawyerList.map((lawyer, index) => (
                <div className="members-item" key={index}>
                  <Link to={`/members/profile/${lawyer.id}`}>
                    <img
                      src={lawyer.mainImg ? lawyer.mainImg : img.defaultLawyer}
                      alt=""
                    />
                  </Link>
                  <p className="p1">
                    <span>{lawyer.nameKo}</span>
                    <span>{lawyer.position}</span>
                  </p>
                  <p className="p2">{lawyer.nameCh}</p>
                  <p className="p2">{lawyer.email}</p>
                  <p className="class-wrap">
                    {lawyer.firstMainCareer.length > 1 && (
                      <p className="p3">{lawyer.firstMainCareer}</p>
                    )}
                    {lawyer.secondMainCareer.length > 1 && (
                      <p className="p3">{lawyer.secondMainCareer}</p>
                    )}
                  </p>
                </div>
              ))}
            </div>
            {showAll && isFirstPage && (
              <button className="toggle-button" onClick={handleToggle}>
                더보기
                <img src={img.icons.down} alt="" />
              </button>
            )}
            {!showAll && !isFirstPage && (
              <button className="toggle-button" onClick={handleToggle}>
                접기
                <img src={img.icons.up} alt="" />
              </button>
            )}
          </div>
        </div>
        <div className="content-right">
          <p onClick={() => scrollToSection(introduce)}>
            <span>01</span> 소개
          </p>
          <p onClick={() => scrollToSection(workSectionRef)}>
            <span>02</span> 대표사례
          </p>
          <p onClick={() => scrollToSection(membersSectionRef)}>
            <span>03</span> 구성원
          </p>
          <p onClick={() => scrollToSection(newsSectionRef)}>
            <span>04</span> 언론보도
          </p>
        </div>
      </div>
      {!isMediaLoading && (
        <div className="recent-news" ref={newsSectionRef}>
          <p>
            언론보도
            <Link to="/news">
              <button className="more">
                <img src={img.more} alt="" />
              </button>
            </Link>
          </p>
          <div className="swiper-container">
            <button className="nav-button prev" onClick={handlePrev}>
              <img src={img.icons.ArrowLeft} alt="Previous Slide" />
            </button>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={1}
              spaceBetween={5}
              scrollbar={{ draggable: true }}
              slidesOffsetBefore={0}
              slidesOffsetAfter={0}
              onSwiper={(swiper: any) => (swiperRef.current = swiper)}
              breakpoints={{
                300: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: swiperPerViewCount(mediaList.length, 4),
                },
              }}
            >
              {mediaList.map((item, index) => (
                <SwiperSlide key={index}>
                  <Link
                    to={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="newsletter-content">
                      <div className="mark">
                        <span>{item.work_field}</span>
                      </div>
                      <div className="title">
                        <span>{item.title}</span>
                      </div>
                      <div className="bottom">
                        <span className="date">{item.media_company}</span>
                        <span className="date">
                          {formatDateToDotSeparated(item.mediaDate)}
                        </span>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
            <button className="nav-button next" onClick={handleNext}>
              <img src={img.icons.ArrowRight} alt="Next Slide" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkFieldDetailPage;
