import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import CheckBox from "../../../components/Admin/Common/CheckBox";
import DropdownSrc from "../../../assets/images/ic_admin_dropdowArr.svg";
import { MAIN_TASKS_LIST, ADD_TASKS_LIST } from "../../../const/admin";
import {
  getMediaItem,
  createMedia,
  updateMedia,
  getMediaLawyers,
} from "../../../api/admin";
import Divider from "../../../components/Admin/Common/Divider";

interface Contents {
  title: string;
  link: string;
  mediaDate: string;
  media_company: string;
  workFieldList: string[];
  lawyerIdList: number[];
  isVisible: boolean;
}

export default function MediaRegister() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];
  const [isLoading, setIsLoading] = useState(false);
  const [isWorkFieldOpen, setIsWorkFieldOpen] = useState(false);
  const [isLawyerOpen, setIsLawyerOpen] = useState(false);
  const [lawyerList, setLawyerList] = useState<Array<[string, string]>>([]);
  const [contents, setContents] = useState<Contents>({
    title: "",
    link: "",
    media_company: "",
    isVisible: true,
    mediaDate: today,
    workFieldList: [],
    lawyerIdList: [],
  });
  const dropdownRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    (async () => {
      if (state?.id) {
        const { data } = await getMediaItem(Number(state?.id));
        setContents(data);
      }
      const { data: lawyerData } = await getMediaLawyers();
      setLawyerList(Object.entries(lawyerData));
    })();
  }, [state?.id]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsWorkFieldOpen(false);
        setIsLawyerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleSingleCheck = () => {
    setContents({ ...contents, isVisible: !contents.isVisible });
  };

  const isDropDownItemSelected = <T extends string | number>(
    item: T,
    list: T[]
  ) => {
    return list.includes(item);
  };

  const dropDownPlaceHolder = <T extends string | number>(
    list: T[],
    isLawyer?: boolean
  ): string => {
    if (list.length === 0) {
      return "선택";
    }
    return `${list[0]} 외 ${list.length - 1}${isLawyer ? "명" : "개"}`;
  };

  const handleWorkFieldCheck = (workField: string) => {
    if (isDropDownItemSelected(workField, contents.workFieldList)) {
      return setContents({
        ...contents,
        workFieldList: contents.workFieldList.filter(
          (field) => field !== workField
        ),
      });
    }
    setContents({
      ...contents,
      workFieldList: [...contents.workFieldList, workField],
    });
  };

  const handleLawyerCheck = (lawyerId: number) => {
    if (isDropDownItemSelected(lawyerId, contents.lawyerIdList)) {
      return setContents({
        ...contents,
        lawyerIdList: contents.lawyerIdList.filter((id) => id !== lawyerId),
      });
    }
    setContents({
      ...contents,
      lawyerIdList: [...contents.lawyerIdList, lawyerId],
    });
  };

  const onClickSubmitButton = async () => {
    try {
      setIsLoading(true);
      const combinedData = {
        ...contents,
      };
      if (window.confirm("게시글을 등록하시겠습니까?")) {
        if (state?.id) {
          await updateMedia(state?.id, combinedData);
        } else {
          await createMedia(combinedData);
        }
        navigate("/admin/media-manegement");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="admin-common-container">
      <h2 className="admin-common-title">언론보도 작성</h2>
      <div className="admin-post-registerCon">
        <div className="admin-post-btnLine">
          <div className="admin-media-inputWrap">
            <p>카테고리</p>
            <input type="text" value="언론보도" disabled readOnly />
          </div>
          <div className="admin-media-inputWrap">
            <p>업무분야</p>
            <button
              type="button"
              className="admin-post-selBtn"
              onClick={() => setIsWorkFieldOpen(!isWorkFieldOpen)}
            >
              <p
                style={{
                  color: contents.workFieldList.length === 0 ? "" : "black",
                }}
              >
                {dropDownPlaceHolder(contents.workFieldList)}
              </p>
              <img src={DropdownSrc} alt="드롭다운 아이콘" />
            </button>
            <div>
              {isWorkFieldOpen && (
                <ul className="admin-media-dropList" ref={dropdownRef}>
                  {[...MAIN_TASKS_LIST, ...ADD_TASKS_LIST].map((workField) => (
                    <div key={`work-${workField}`}>
                      <li className="admin-media-dropItem">
                        {workField === "기업 감사/내부통제 지원센터" && (
                          <Divider />
                        )}
                        <label className="admin-custom-checkbox">
                          <input
                            type="checkbox"
                            onClick={() => handleWorkFieldCheck(workField)}
                            checked={isDropDownItemSelected(
                              workField,
                              contents.workFieldList
                            )}
                            readOnly
                          />
                          <span className="admin-checkmark"></span>
                          <p>{workField}</p>
                        </label>
                      </li>
                    </div>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="admin-media-inputWrap">
            <p>언론사</p>
            <input
              type="text"
              value={contents.media_company}
              onChange={(e) =>
                setContents({ ...contents, media_company: e.target.value })
              }
              placeholder="언론사를 입력하세요"
            />
          </div>

          <div className="admin-media-inputWrap">
            <p>기사날짜</p>
            <input
              type="date"
              value={contents.mediaDate || today}
              onChange={(e) => {
                setContents({ ...contents, mediaDate: e.target.value });
              }}
            />
          </div>

          <div className="admin-media-inputWrap">
            <p>구성원</p>
            <button
              type="button"
              className="admin-post-selBtn"
              onClick={() => setIsLawyerOpen(!isLawyerOpen)}
            >
              <p
                style={{
                  color: contents.lawyerIdList.length === 0 ? "" : "black",
                }}
              >
                {dropDownPlaceHolder(
                  lawyerList
                    .filter(([id, _]) =>
                      isDropDownItemSelected(Number(id), contents.lawyerIdList)
                    )
                    .map(([_, lawyer]) => lawyer),
                  true
                )}
              </p>
              <img src={DropdownSrc} alt="드롭다운 아이콘" />
            </button>
            <div>
              {isLawyerOpen && (
                <ul className="admin-media-dropList" ref={dropdownRef}>
                  {lawyerList.map(([id, lawyer]) => (
                    <div key={`lawyer-${id}`}>
                      <li className="admin-media-dropItem">
                        <label className="admin-custom-checkbox">
                          <input
                            type="checkbox"
                            onClick={() => handleLawyerCheck(Number(id))}
                            checked={isDropDownItemSelected(
                              Number(id),
                              contents.lawyerIdList
                            )}
                            readOnly
                          />
                          <span className="admin-checkmark"></span>
                          <p>{lawyer}</p>
                        </label>
                      </li>
                    </div>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>

        <div className="admin-post-inputWrap" style={{ marginTop: "40px" }}>
          <p>제목</p>
          <input
            type="text"
            value={contents.title}
            onChange={(e) =>
              setContents({ ...contents, title: e.target.value })
            }
            placeholder="제목을 입력하세요"
          />
        </div>

        <div className="admin-post-inputWrap">
          <p>링크</p>
          <input
            type="text"
            value={contents.link}
            onChange={(e) => setContents({ ...contents, link: e.target.value })}
            placeholder="링크를 입력하세요"
          />
        </div>

        <div className="admin-media-emtpyWrap"></div>

        <div className="admin-register-btnWrap">
          <button
            type="button"
            disabled={isLoading}
            onClick={onClickSubmitButton}
            className="admin-register-savebtn"
          >
            등록
          </button>
          <CheckBox
            isVisible={contents.isVisible}
            label="비공개"
            handleSingleCheck={handleSingleCheck}
          />
        </div>
      </div>
    </section>
  );
}
