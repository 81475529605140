import MainSection from "./MainSection";
import NewsSection from "./NewsSection";
import HiredLawyerSection from "./HiredLawyerSection";
import LawvaxMap from "./LawvaxMap";
import MediaSection from "./MediaSection";
import Popup from "../../components/Popup";

const LandingPage = () => {
  return (
    <div className="landing-page">
      <Popup />
      <MainSection />
      <NewsSection />
      <MediaSection />
      <HiredLawyerSection />
      <LawvaxMap />
    </div>
  );
};

export default LandingPage;
