import axiosInstance from "./axiosInstance";

export const getNewsData = async (
  page: number,
  category: string,
  size?: number,
  search?: string
) => {
  try {
    const response = await axiosInstance.get(
      `/v2/user/news/list/news-list-page`,
      {
        params: {
          page: page,
          category: category,
          size: size,
          search: search,
        },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error("법인소식 페이지 검색 실패: " + error.message);
    } else {
      throw new Error("법인소식 페이지 검색 실패: 알 수 없는 오류");
    }
  }
};

export const getMediaNewsData = async (
  page: number,
  size?: number,
  search?: string
) => {
  try {
    const response = await axiosInstance.get(
      `/v2/user/media/list/media-list-page`,
      {
        params: {
          page: page,
          size: size,
          search: search,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error("법인소식 페이지 검색 실패: " + error.message);
    } else {
      throw new Error("법인소식 페이지 검색 실패: 알 수 없는 오류");
    }
  }
};
