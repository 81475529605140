import { useState } from "react";
import img from "../../assets/images/img";
import { Link, useNavigate } from "react-router-dom";
import useSearchStore from "../../stores/searchStore";
import { getLandingSearch } from "../../api/getLandingSearch";
import useUpdateUrlWithQuery from "../../utils/useUpdateUrlWithQuery";

const LandingSearchPage = () => {
  const navigate = useNavigate();
  const { searchResults, keyword, setSearchResults, setKeyword } =
    useSearchStore();
  const [tempKeyword, setTempKeyword] = useState(keyword);
  const updateUrlWithQuery = useUpdateUrlWithQuery();

  const handleBlur = () => {
    setTimeout(() => {}, 100);
  };

  // 검색 함수
  const handleSearch = async () => {
    try {
      setKeyword(tempKeyword);
      const results = await getLandingSearch(tempKeyword.trim());
      setSearchResults(results);
      updateUrlWithQuery([
        { paramsKey: "keyword", paramsValue: tempKeyword.trim() },
      ]);
    } catch (error) {
      console.error("검색 실패:", error);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (tempKeyword.trim() === "") {
        alert("검색어를 입력해주세요.");
        updateUrlWithQuery([], true);
        return;
      }

      handleSearch();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempKeyword(e.target.value);
  };

  const handleMoreClick = (section: string) => {
    navigate(`/news?category=${section}`);
  };

  return (
    <div className="landing-search-page">
      {/* 검색 바 */}
      <div className="process">
        <img src={img.icons.home} alt="" />
        <span>HOME</span>
        <span className="search">{">"}</span>
        <span className="search">{keyword ? keyword : "검색결과"}</span>
      </div>
      <div className="search-bar-wrap">
        <div className="search-bar">
          <input
            type="text"
            placeholder="키워드를 입력해주세요."
            className="search-input"
            onBlur={handleBlur}
            value={tempKeyword}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <img
            className="search-icon"
            src={img.icons.search}
            alt="search-icon"
            onClick={() => handleSearch()}
          />
        </div>
      </div>
      {/* 구성원 섹션 */}
      {searchResults?.구성원?.length > 0 && (
        <div className="members-section">
          <div className="sub-title">
            <p>구성원</p>
          </div>
          <div className="members-wrap">
            {searchResults?.구성원.map((lawyer: any, index: number) => (
              <div key={index} className="lawyer-item">
                <Link to={`/members/profile/${lawyer.id}`}>
                  <img src={lawyer.mainImg} alt={lawyer.nameKo} />
                </Link>
                <div className="text-wrap">
                  <p className="p1">
                    <span>{lawyer.nameKo}</span>
                  </p>
                  <p className="p2">{lawyer.position}</p>
                  <p className="email">{lawyer.email}</p>
                  <p className="class-wrap">
                    {lawyer.firstMainCareer && (
                      <p className="p3">{lawyer.firstMainCareer}</p>
                    )}
                    {lawyer.secondMainCareer && (
                      <p className="p3">{lawyer.secondMainCareer}</p>
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {/* 업무분야 섹션 */}
      {searchResults?.업무분야?.length > 0 && (
        <div className="service-section">
          <div className="sub-title">
            <p>업무분야</p>
          </div>
          <div className="content">
            <ul>
              {searchResults.업무분야.map((field: string, index: number) => (
                <Link to={`/workfield/detail/${field}`} key={index}>
                  <li>{field}</li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      )}
      {/* 법인소식 섹션 */}
      {searchResults?.Lawvax소식?.length > 0 && (
        <div className="news-section01">
          <div className="sub-title">
            <p>
              Lawvax소식
              <button
                className="more"
                onClick={() => handleMoreClick("Lawvax소식")}
              >
                <img src={img.more} alt="" />
              </button>
            </p>
          </div>
          <div className="content">
            {searchResults.Lawvax소식.map((news: any, index: number) => (
              <Link
                to={`/news/post/${news.id}?category=Lawvax소식`}
                key={index}
              >
                <div key={index} className="news-item">
                  <h3 className="title">{news.title}</h3>
                  <p className="content">{news.summary}</p>
                  <p className="date">{news.createdAt.slice(0, 10)}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
      {/* 언론보도 섹션 */}
      {searchResults?.언론보도?.length > 0 && (
        <div className="news-section02">
          <div className="sub-title">
            <p>
              언론보도
              <button
                className="more"
                onClick={() => handleMoreClick("언론보도")}
              >
                <img src={img.more} alt="" />
              </button>
            </p>
          </div>
          <div className="content">
            {searchResults?.언론보도.map((news: any, index: number) => (
              <div key={index} className="news-item">
                <Link to={news.link} target="_blank" rel="noopener noreferrer">
                  <h3 className="title">{news.title}</h3>
                  <p className="wrap">
                    <p className="company">{news.media_company}</p>
                    <p className="bar">|</p>
                    <p className="date">{news.mediaDate?.slice(0, 10)}</p>
                  </p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
      {/* 인재영입 섹션 */}
      {searchResults?.인재영입?.length > 0 && (
        <div className="news-section03">
          <div className="sub-title">
            <p>
              인재영입
              <button
                className="more"
                onClick={() => handleMoreClick("인재영입")}
              >
                <img src={img.more} alt="" />
              </button>
            </p>
          </div>
          <div className="content">
            {searchResults.인재영입.map((news: any, index: number) => (
              <Link to={`/news/post/${news.id}?category=인재영입`} key={index}>
                <div key={index} className="news-item">
                  <h3 className="title">{news.title}</h3>
                  <p className="content">{news.summary}</p>
                  <p className="date">{news.createdAt.slice(0, 10)}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
      {
        // 검색 결과가 없을 때
        searchResults &&
          Object.keys(searchResults).every(
            (key) => searchResults[key].length === 0
          ) && (
            <div className="no-result">
              <p>검색 결과가 없습니다.</p>
            </div>
          )
      }
    </div>
  );
};

export default LandingSearchPage;
