import { create } from "zustand";

interface MemberStore {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}

interface MemberIsFirstPageStore {
  isFirstPage: boolean;
  setIsFirstPage: (isFirstPage: boolean) => void;
}

export const useMemberStore = create<MemberStore>((set) => ({
  searchTerm: "",
  setSearchTerm: (term) => set({ searchTerm: term }),
}));

export const useMemberIsFirstPageStore = create<MemberIsFirstPageStore>(
  (set) => ({
    isFirstPage: true,
    setIsFirstPage: (isFirstPage) => set({ isFirstPage }),
  })
);
