// 업무분야 상세 > 뉴스레터

import axiosInstance from "./axiosInstance";

export const getWorkFieldNewsLetter = async (workField: string) => {
  try {
    const response = await axiosInstance.get(
      `/v2/user/media/list/work-field-page`,
      {
        params: {
          work_field: workField,
        },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error("업무분야 상세 뉴스레터 조회 실패: " + error.message);
    } else {
      throw new Error("업무분야 상세 뉴스레터 조회 실패 : 알 수 없는 오류");
    }
  }
};

export const getLawyerMediaList = async (lawyerId: number, size?: number) => {
  try {
    const response = await axiosInstance.get(
      `/v2/user/media/list/lawyer-page`,
      {
        params: {
          lawyer_id: lawyerId,
          size: size,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error("변호사 뉴스레터 조회 실패: " + error.message);
    } else {
      throw new Error("변호사 뉴스레터 조회 실패 : 알 수 없는 오류");
    }
  }
};
