import { useEffect, useState, useRef } from "react";
import { getMembersLandingPage } from "../api/getMembersLandingPage";
import img from "../assets/images/img";
import { Link } from "react-router-dom";

interface Lawyer {
  id: number;
  mainImg: string;
  name: string;
  email: string;
  position: string;
  firstMainCareer: string;
  secondMainCareer: string;
}

export const LandingMemberSection01: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const [lawyers, setLawyers] = useState<Lawyer[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // 타이머 ID 저장

  const shuffleLawyers = (lawyers: Lawyer[]) => {
    return lawyers.sort(() => Math.random() - 0.5);
  };

  useEffect(() => {
    const fetchLawyers = async () => {
      try {
        const response = await getMembersLandingPage();
        setLawyers(shuffleLawyers(response || []));

        setIsLoading(false);
      } catch (error) {
        console.error("변호사 정보 조회 중 에러 발생:", error);
        setIsLoading(false);
      }
    };

    fetchLawyers();
  }, []);

  const startAutoSlide = () => {
    intervalRef.current = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % lawyers.length);
        setFade(false);
      }, 500);
    }, 3000);
  };

  const stopAutoSlide = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    if (!isLoading && lawyers.length > 0) {
      startAutoSlide();
      return () => stopAutoSlide();
    }
    // eslint-disable-next-line
  }, [isLoading, lawyers]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (lawyers.length === 0) {
    return <div>No lawyers available</div>;
  }

  const currentLawyer = lawyers[currentIndex] || {
    name: "",
    position: "",
    firstMainCareer: "",
  };

  const handlePrev = () => {
    stopAutoSlide();
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? lawyers.length - 1 : prevIndex - 1
      );
      setFade(false);
    }, 500);
    startAutoSlide();
  };

  const handleNext = () => {
    stopAutoSlide();
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % lawyers.length);
      setFade(false);
    }, 500);
    startAutoSlide();
  };

  return (
    <div className="section01">
      <button className="nav-button prev" onClick={handlePrev}>
        <img src={img.icons.ArrowLeft} alt="" />
      </button>
      <div className={`slider-content ${fade ? "fade" : ""}`}>
        <div className="img-wrap">
          <Link to={`/members/profile/${currentLawyer.id}`}>
            <img
              src={
                currentLawyer.mainImg === null
                  ? img.defaultLawyer
                  : currentLawyer.mainImg
              }
              alt=""
            />
          </Link>
          <div className="text-wrap">
            <p>
              <span className="name">{currentLawyer.name}</span>
              <span className="job">{currentLawyer.position}</span>
            </p>
            <p className="email">{currentLawyer.email}</p>
            <p className="class-wrap">
              {currentLawyer.firstMainCareer && (
                <span className="class">{currentLawyer.firstMainCareer}</span>
              )}
              {currentLawyer.secondMainCareer && (
                <span className="class">{currentLawyer.secondMainCareer}</span>
              )}
            </p>
          </div>
        </div>
      </div>
      <button className="nav-button next" onClick={handleNext}>
        <img src={img.icons.ArrowRight} alt="" />
      </button>
    </div>
  );
};
