import axiosInstance from "./axiosInstance";

export const getNewsDetailListData = async (
  id: number,
  category: string,
  size?: number
) => {
  try {
    const response = await axiosInstance.get(
      `/v2/user/news/${id}/next-news?category=${category}${size ? `&size=${size}` : ""}`
    );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(
        "법인소식 뉴스 상세 속 다음 뉴스 리스트 검색 실패: " + error.message
      );
    } else {
      throw new Error(
        "법인소식 뉴스 상세 속 다음 뉴스 리스트 검색 실패: 알 수 없는 오류"
      );
    }
  }
};
