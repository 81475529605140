import { useEffect, useRef, useState } from "react";
import img from "../../assets/images/img";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getCenterMembers } from "../../api/getCenterMembers";
import { getCenterNewsLetter } from "../../api/getCenterNewsLetter";
import { formatDateToDotSeparated } from "../../utils/dateFormat";
import { swiperPerViewCount } from "../../utils/swiperPerViewCount";

interface Lawyer {
  id: number;
  mainImg: string;
  nameKo: string;
  nameCh: string;
  position: string;
  firstMainCareer: string;
  secondMainCareer: string;
  email: string;
}

interface MediaItems {
  id: number;
  title: string;
  work_field: string;
  link: string;
  media_company: string;
  mediaDate: string;
}

const TechCenterPage = () => {
  const [activeTab, setActiveTab] = useState("소개");
  const [showAll, setShowAll] = useState(false);
  const [mediaData, setMediaData] = useState<MediaItems[]>([]);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [lawyers, setLawyers] = useState<Lawyer[]>([]);
  const swiperRef = useRef<any>(null);

  // eslint-disable-next-line
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 769px)" });

  const fetchMembers = async () => {
    try {
      const response = await getCenterMembers("기술 보호 센터", isFirstPage);
      setLawyers(response.lawyerList);
      setShowAll(response.hasNextPage);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNewsLetter = async () => {
    try {
      const response = await getCenterNewsLetter("기술 보호 센터");
      setMediaData(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMembers();
    fetchNewsLetter();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchMembers();
    // eslint-disable-next-line
  }, [isFirstPage]);

  const handleToggle = () => {
    setShowAll((prevState) => !prevState);
    setIsFirstPage((prevState) => !prevState);
  };

  const scrollToSection = (sectionId: any) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const sectionTop =
        section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: sectionTop - 150,
        behavior: "smooth",
      });
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <div className="tech-center-page">
      {isDesktop ? (
        <div className="main">
          <div className="text-wrap">
            <h1>
              Technology
              <br /> Protection Center
            </h1>
          </div>
          <div className="img-wrap">
            <img src={img.CenterMain01} alt="" />
          </div>
        </div>
      ) : (
        <div className="main">
          <div className="text-wrap">
            <h1>
              Technology
              <br />
              Protection Center
            </h1>
          </div>
        </div>
      )}
      <div className="theme-select">
        <button
          className={activeTab === "소개" ? "active" : ""}
          onClick={() => {
            setActiveTab("소개");
            scrollToSection("section01");
          }}
        >
          소개
        </button>
        <button
          className={activeTab === "서비스소개" ? "active" : ""}
          onClick={() => {
            setActiveTab("서비스소개");
            scrollToSection("section02");
          }}
        >
          서비스 소개
        </button>

        <button
          className={activeTab === "구성원" ? "active" : ""}
          onClick={() => {
            setActiveTab("구성원");
            scrollToSection("section04");
          }}
        >
          구성원
        </button>
        <button
          className={activeTab === "언론보도" ? "active" : ""}
          onClick={() => {
            setActiveTab("언론보도");
            scrollToSection("section05");
          }}
        >
          언론보도
        </button>
      </div>
      {isDesktop ? (
        <div className="section01" id="section01">
          <div className="img-wrap">
            <img src={img.CenterMain02} alt="" />
          </div>
          <div className="text-wrap">
            <h1>『LawVax 기술보호센터』</h1>
            <p className="p2">
              『LawVax 기술보호센터』는 기업의 첨단기술 관련 법률 리스크 사전
              예방 및 리스크 발생시 신속 대응 지원을 위해 설립하였으며, <br />
              보안컨설턴트 및 전문 변호사들이 기술보호를 위한 컨설팅·법률대응 및
              영업비밀·競業 관련 분쟁 등에 대한 
              <br />
              토탈 법률 서비스를 제공하고 있습니다. 기술보호센터는 기술보호
              생태계를 조성하여 공익에 기여하는 한편, <br />
              경제안보 시대에 기업의 생존과 직결된 공급망·기술수출 통제 등
              새로운 법률 이슈 대응을 선도해 나갈 계획입니다. 
            </p>
          </div>
        </div>
      ) : (
        <div className="section01" id="section01">
          <div className="text-wrap">
            <h1>『LawVax 기술보호센터』</h1>
            <p className="p2">
              『LawVax 기술보호센터』는 기업의 첨단기술 관련 법률 리스크 사전
              예방 및 리스크 발생시 신속 대응 지원을 위해 설립하였으며,{" "}
              보안컨설턴트 및 전문 변호사들이 기술보호를 위한 컨설팅·법률대응 및
              영업비밀·競業 관련 분쟁 등에 대한 토탈 법률 서비스를 제공하고
              있습니다. <br />
              <br />
              기술보호센터는 기술보호 생태계를 조성하여 공익에 기여하는 한편,{" "}
              경제안보 시대에 기업의 생존과 직결된 공급망·기술수출 통제 등
              새로운 법률 이슈 대응을 선도해 나갈 계획입니다. 
            </p>
          </div>
        </div>
      )}
      <h2 className="sub-title roman-title">Our Service</h2>
      {isDesktop ? (
        <div className="section02" id="section02">
          <div className="content">
            <div className="img-wrap01">
              <img src={img.center06} alt="" />
            </div>
            <div className="text-wrap01">
              <p className="p1">영업비밀 보호·대응 관련 원스톱 서비스</p>
              <p className="p2">
                기술보호센터는 기업의 핵심인력 입·퇴사시 영업비밀 보호를 위한{" "}
                <br />
                보안 교육·컨설팅을 지원하고, 기술유출 징후 등 리스크 발생 시{" "}
                <br />
                자체 대응 지원과 사법적 구제 등 통합적인 서비스를 제공합니다.
              </p>
            </div>
          </div>
          <div className="content">
            <div className="text-wrap02">
              <p className="p1">
                핵심기술 수출 및 M&A
                <br />
                정부의 기술보호 관련 법적 지원
              </p>
              <p className="p2">
                최근 국가 핵심기술·방산기술의 해외수출 및 M&A 관련 정부의
                승인·신고 절차에 있어
                <br />
                기술보호실태 사전 조사 등 법적 의무사항이 많아지고 있으나 개별
                기업 차원에서
                <br />
                일일이 대응하기 어려운 상황입니다. <br />
                <br />
                LawVax는 이를 위해 정부의 기술보호지침에 따른 보안관리가
                이루어지도록 <br />
                컨설팅을 실시하여 원활하게 관련 절차가 진행될 수 있도록 돕고
                있으며, <br />
                행정·사법 절차에 적법하게 대응하도록 지원하고 있습니다.
              </p>
            </div>
            <div className="img-wrap02">
              <img src={img.center07} alt="" />
            </div>
          </div>
        </div>
      ) : (
        <div className="section02" id="section02">
          <div className="content">
            <div className="img-wrap">
              <img src={img.center06} alt="" />
            </div>
            <div className="text-wrap" style={{ textAlign: "left" }}>
              <p
                className="p1"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                영업비밀 보호·대응 관련 원스톱 서비스
              </p>
              <p className="p2">
                기술보호센터는 기업의 핵심인력 입·퇴사시 영업비밀 보호를 위한{" "}
                보안 교육·컨설팅을 지원하고, 기술유출 징후 등 리스크 발생 시{" "}
                자체 대응 지원과 사법적 구제 등 통합적인 서비스를 제공합니다.
              </p>
            </div>
          </div>
          <div className="content">
            <div className="img-wrap">
              <img src={img.center07} alt="" />
            </div>
            <div className="text-wrap" style={{ textAlign: "left" }}>
              <p
                className="p1"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                핵심기술 수출 및 M&A
                <br />
                정부의 기술보호 관련 법적 지원
              </p>
              <p className="p2">
                최근 국가 핵심기술·방산기술의 해외수출 및 M&A 관련 정부의
                승인·신고 절차에 있어 기술보호실태 사전 조사 등 법적 의무사항이
                많아지고 있으나 개별 기업 차원에서 일일이 대응하기 어려운
                상황입니다. <br />
                <br />
                LawVax는 이를 위해 정부의 기술보호지침에 따른 보안관리가
                이루어지도록 컨설팅을 실시하여 원활하게 관련 절차가 진행될 수
                있도록 돕고 있으며, 행정·사법 절차에 적법하게 대응하도록
                지원하고 있습니다.
              </p>
            </div>
          </div>
        </div>
      )}

      {lawyers.length > 0 && (
        <>
          <h2 className="sub-title roman-title">Our Team</h2>
          <div className="section04" id="section04">
            <div className="members-wrap">
              {lawyers?.map((lawyer: Lawyer, index: number) => (
                <div key={index} className="lawyer-item">
                  <Link to={`/members/profile/${lawyer.id}`}>
                    <img src={lawyer.mainImg} alt={lawyer.nameKo} />
                  </Link>
                  <div className="text-wrap">
                    <p className="p1">
                      <span>{lawyer.nameKo}</span>
                    </p>
                    <p className="p2">{lawyer.position}</p>
                    <p
                      className="p2"
                      style={{ display: "block", marginLeft: "0px" }}
                    >
                      {lawyer.email}
                    </p>
                    <p className="class-wrap">
                      {lawyer.firstMainCareer && (
                        <p className="p3">{lawyer.firstMainCareer}</p>
                      )}
                      {lawyer.secondMainCareer && (
                        <p className="p3">{lawyer.secondMainCareer}</p>
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {showAll && isFirstPage && (
              <button className="toggle-button" onClick={handleToggle}>
                더보기
                <img src={img.icons.down} alt="더보기 아이콘" />
              </button>
            )}
            {!showAll && !isFirstPage && (
              <button className="toggle-button" onClick={handleToggle}>
                접기
                <img src={img.icons.up} alt="접기 아이콘" />
              </button>
            )}
          </div>
        </>
      )}
      {mediaData?.length > 0 && (
        <div className="section05" id="section05">
          <div className="sub-title">
            <p>
              언론보도
              <Link to="/news">
                <button className="more">
                  <img src={img.more} alt="" />
                </button>
              </Link>
            </p>
          </div>
          <div className="swiper-container">
            <button className="nav-button prev" onClick={handlePrev}>
              <img src={img.icons.ArrowLeft} alt="Previous Slide" />
            </button>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={1}
              spaceBetween={10}
              scrollbar={{ draggable: true }}
              slidesOffsetBefore={0}
              slidesOffsetAfter={0}
              onSwiper={(swiper: any) => (swiperRef.current = swiper)}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                768: {
                  slidesPerView: swiperPerViewCount(mediaData.length, 4),
                  spaceBetween: 5,
                },
              }}
            >
              {mediaData?.map((item: MediaItems, index: number) => (
                <SwiperSlide key={index}>
                  <Link
                    to={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="newsletter-content">
                      <div className="mark">
                        <span>{item.work_field}</span>
                      </div>
                      <div className="title">
                        <span>{item.title}</span>
                      </div>
                      <div className="bottom">
                        <span className="date">{item.media_company}</span>
                        <span className="date">
                          {formatDateToDotSeparated(item.mediaDate)}
                        </span>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
            <button className="nav-button next" onClick={handleNext}>
              <img src={img.icons.ArrowRight} alt="Next Slide" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TechCenterPage;
